import i18n from '@lib/i18n';
import Currency from '@lib/currency';

let baseUrl = '';
baseUrl = process.env.REACT_APP_BASE_URL;
/*if (process.env.NODE_ENV !== 'production') {
  //LOCAL DEV:
  //baseUrl =  process.env.REACT_APP_APIURL //'https://api-ics.externalytics.com/api' //https://ics.stage2.openeconomics.ovh/api
  //baseUrl = 'http://127.0.0.1/api'
} else {
  //baseUrl =  window.icsApiUrl || 'https://api-ics.externalytics.com/api'; //'http://localhost/api' //https://ics.stage2.openeconomics.ovh/api
}*/
//baseUrl =  window.icsApiUrl || 'https://api-ics.externalytics.com/api'; //'http://localhost/api' //https://ics.stage2.openeconomics.ovh/api

const apiUrl = baseUrl + '/v1';
const credentials = {
  token: localStorage.getItem('token') || '',
};

const handleErrors = (response) => {
  if (!response.ok) {
    const responseError = {
      statusText: response.statusText,
      status: response.status,
    };
    throw responseError;
  }
  //console.log(response)
  return response.json();
};

const getAuth = () => {
  //return btoa(`${credentials.username}:${credentials.password}`);
  //console.log('getAuth', credentials.token )
  return credentials.token;
};

export const getDefaultHeaders = () => {
  const auth = getAuth();
  //console.log('auth', auth)
  return {
    Authorization: `Bearer ${auth}`,
    'Content-Type': 'application/json',
  };
};

export const getapiUrl = (onlyUrl = false) => {
  return onlyUrl ? apiUrl : `${apiUrl}/${Currency.currency}/${i18n.language}`;
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const checkLogin = async function (email, password, loginType) {
  //console.log('checkLogin', baseUrl)
  //console.log('checkLogin', email, password, loginType)

  var formBody = `email=${email}&password=${password}&loginType=${loginType}`;
  var returnCode = 200;
  /* const response = */ await fetch(`${baseUrl}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody,
  })
    .then(handleErrors)
    .then((r) => {
      //console.log('mm', r, loginType, email)
      //console.log('r role', r.data.role)
      //console.log('r projectId', r.data.projectId)

      credentials.token = r.accessToken;
      localStorage.setItem('token', credentials.token);
      localStorage.setItem('ics_project_id', null);
      localStorage.setItem('ics_role', r.data.role);
      localStorage.setItem('ics_email', null);

      if (Number(r.data.role) === 1) {
        localStorage.setItem('ics_client_id', email);
        localStorage.setItem('ics_request_id', password);
        localStorage.setItem('ics_project_id', r.data.projectId);
      } else {
        localStorage.setItem('ics_email', email);
      }
    })
    .catch((err) => {
      returnCode = err.status;
      return err.status;
    });
  /*
  const respJson = response.json()
  console.log(respJson)
  if (response.status === 200) {                
    credentials.token = respJson.accessToken
    localStorage.setItem('token', credentials.token)       
  } else {
    credentials.token = null
    localStorage.setItem('token', credentials.token)
  }*/
  return returnCode;
};

export const checkEmailLogin = ({ email }) =>
  fetch(`${apiUrl}/emailLogin`, email)
    .then((r) => (r.status < 300 && r.status >= 200 ? 200 : r.status))
    .then((r) => {
      if (r === 200) {
        //credentials.username = username
        //credentials.password = password
        //localStorage.setItem('external_user', username)
        //localStorage.setItem('external_password', password)
      }
      return r;
    });

/*export const checkAuth = async () => {
  console.log('credentials.token', credentials.token); //errore fetch se vuoto 
  try {
    const resp = await fetch(`${baseUrl}/test/aa${credentials.token}`, {
      //method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        //accept: 'application/json',
      },
      //body: JSON.stringify(survey),
    }).then(response => response);

    //console.log('resp', resp);
    const response = resp.status < 300 && resp.status >= 200 ? 200 : resp.status;
    console.log('response', response);

    return response;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}*/
export const checkAuth = async () => {
  try {
    const resp = await fetch(`${baseUrl}/test/${credentials.token}`, {
      //method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      //body: JSON.stringify(survey),
    }).then((response) => response);

    //console.log('resp', resp);
    const response = resp.status < 300 && resp.status >= 200 ? 200 : resp.status;
    //console.log('response', response);

    return response;
  } catch (err) {
    //console.log('err', err);
    return null;
  }
};
/*
export const checkAuth = () => {
  fetch(`${baseUrl}/test/${credentials.token}`, { headers: { ...getDefaultHeaders() } }).then((r) =>
    r.status < 300 && r.status >= 200 ? 200 : r.status)
  };
*/

export const logout = () => {
  localStorage.removeItem('ics_client_id');
  localStorage.removeItem('ics_request_id');
  localStorage.removeItem('ics_role');
  localStorage.removeItem('token');

  credentials.username = '';
  credentials.password = '';
  credentials.token = '';
};

//old
export const getScenari = () => {
  // return fetch(`${getapiUrl()}/scenari`, {
  return fetch(`${apiUrl}/scenari`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//old
export const getScenarioMeta = (id) =>
  id
    ? getScenari().then((r) => r.filter((s) => parseInt(s.idScenario) === parseInt(id)).pop())
    : null;

//old
export const getScenario = (idScenario) => {
  return fetch(`${getapiUrl()}/scenari/${idScenario}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//old
export const getCountry = () => {
  // return fetch(`${getapiUrl()}/country`, {
  return fetch(`${apiUrl}/getcountryfm`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//export const getCountryfmMeta = id => id ? getCountryfm().then(r => r.filter(s => parseInt(s.idCountry) === parseInt(id)).pop()) : null

//old
export const getCountryfm = (idCountry) => {
  // console.log('++++++'+idCountry)
  return fetch(`${getapiUrl()}/countryfm/Italy`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//old
export const getAdminOrganization = (value) => {
  return fetch(`${apiUrl}/adminGetOrganization/${value}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};
//old
export const getAdminClasses = async (value) => {
  return fetch(`${apiUrl}/adminGetClasses/${value}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};
//old
export const getAdminRegion = (value) => {
  return fetch(`${apiUrl}/adminGetRegion/${value}`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};
//old
export const getAdminSam = (country, region = null) => {
  if (region == null) {
    return fetch(`${apiUrl}/adminGetSam/${country}`, {
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());
  } else {
    return fetch(`${apiUrl}/adminGetSam/${country}/${region}`, {
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());
  }
};

//old
export const getScenarioSelect = () => {
  return fetch(`${apiUrl}/adminGetScenarioSelect`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//old
/* export const getTranslations = () => {
  return fetch(`${apiUrl}/language`).then((r) => r.json());
}; */

//old
export const getTranslationsUrl = () => `${apiUrl}/language`;

//old
export const getUser = async () => {
  return fetch(`${apiUrl}/user`, {
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//COUNTRIES
export const getCountries = () => {
  return fetch(`${apiUrl}/country`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};
//DATASETS
export const getDataset = (dataSetId) => {
  return fetch(`${apiUrl}/datasets/${dataSetId}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getDatasets = () => {
  return fetch(`${apiUrl}/v1/dataset`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const postDataset = (dataset) => {
  return fetch(`${apiUrl}/v1/dataset`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(dataset),
  }).then((r) => r.json());
};

export const putDataset = (dataset) => {
  return fetch(`${apiUrl}/v1/dataset/${dataset.id}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(dataset),
  }).then((r) => r.json());
};

export const deleteDataset = (dataSetId) => {
  return fetch(`${apiUrl}/v1/dataset/${dataSetId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const createUser = async (email, password, loginType) => {
  const data = { email, password, loginType };
  //console.log('data', data);

  try {
    const resp = await fetch(`${baseUrl}/register/`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    }).then((r) => r.json());

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const getUsers = (role) => {
  return fetch(`${baseUrl}/users?role=${role}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getTranslations = () => {
  return fetch(`${apiUrl}/translations`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getProjects = () => {
  return fetch(`${apiUrl}/projects`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getProject = async (projectId) => {
  try {
    const resp = await fetch(`${apiUrl}/projects/${projectId}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());

    return resp;
  } catch {
    return null;
  }
};

export const createProject = async (project) => {
  try {
    const resp = await fetch(`${apiUrl}/projects/`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(project),
    }).then((r) => r.json());

    //console.log('resp', resp);
    return resp;
  } catch (err) {
    return err;
  }
};

export const editProject = async (project) => {
  try {
    const resp = await fetch(`${apiUrl}/projects/${project.id}`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      body: JSON.stringify(project),
    }).then((r) => r.json());

    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const resp = await fetch(`${apiUrl}/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());

    return resp;
  } catch (err) {
    return err;
  }
};

export const deleteUser = async (userId) => {
  try {
    const resp = await fetch(`${baseUrl}/users/${userId}`, {
      method: 'DELETE',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((r) => r.json());

    return resp;
  } catch (err) {
    return err;
  }
};

export const getQuestions = (size, sector) => {
  return fetch(`${apiUrl}/questions?size=${size}&sector=${sector}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getLocations = () => {
  return fetch(`${apiUrl}/locations`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getRequestIdCheck = (requestID) => {
  return fetch(`${apiUrl}/projects?requestId=${requestID}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((response) => response.ok);
};

export const getRegions = () => {
  return fetch(`${apiUrl}/locations?filterType=nation`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getProvinceByRegion = (region) => {
  return fetch(`${apiUrl}/locations?filterType=region&filterValue=${region}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getTownByProvince = (province) => {
  return fetch(`${apiUrl}/locations?filterType=province&filterValue=${province}`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getProponents = () => {
  return fetch(`${apiUrl}/proponents`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getFinancings = () => {
  return fetch(`${apiUrl}/financingtypes`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getEsgCategories = () => {
  return fetch(`${apiUrl}/esgcategories`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getSectors = () => {
  return fetch(`${apiUrl}/sectors`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getStructureClusters = () => {
  return fetch(`${apiUrl}/structureclusters`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getSizes = () => {
  return fetch(`${apiUrl}/sizes`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getFinDataTaxonomies = () => {
  return fetch(`${apiUrl}/findatataxonomies`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getSroiTechnicalParams = () => {
  return fetch(`${apiUrl}/sroitechnicalparams`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getSroiPeopleParams = () => {
  return fetch(`${apiUrl}/sroipeopleparams`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getInterventions = () => {
  return fetch(`${apiUrl}/interventionclasses`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getInterventionTypes = () => {
  return fetch(`${apiUrl}/interventiontypes`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getRatings = () => {
  return fetch(`${apiUrl}/ratings`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getTypologicalAverage = async (projectId) => {
  try {
    const resp = await fetch(`${apiUrl}/projects/${projectId}/avgrating`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders(),
      },
    }).then((response) => response);
    const response = resp.ok ? resp.json() : null;

    return response;
  } catch (err) {
    //console.log('err', err);
    return null;
  }
};

export const getPercentageESG = (projectId) => {
  return fetch(`${apiUrl}/projects/${projectId}/esgpercentage`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getPercentageSROI = (projectId) => {
  return fetch(`${apiUrl}/projects/${projectId}/sroipercentage`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getSroiRatingsParams = () => {
  return fetch(`${apiUrl}/sroi/sroiratingparams`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

//SURVEY
export const getSurveyQuestions = (surveyUuid) => {
  return fetch(`${apiUrl}/survey/${surveyUuid}/questions`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getProjectStatusByUUID = (surveyUuid) => {
  return fetch(`${apiUrl}/survey/${surveyUuid}/projectstatus`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.text());
};

export const getStats = () => {
  return fetch(`${apiUrl}/stats`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const createSurvey = async (surveyUuid, survey) => {
  try {
    const resp = await fetch(`${apiUrl}/survey/${surveyUuid}/instance/`, {
      method: 'POST',
      headers: {
        ...getDefaultHeaders(),
        accept: 'application/json',
      },
      body: JSON.stringify(survey),
    }).then((response) => response.ok);

    return resp;
  } catch (err) {
    return err;
  }
};

export const putESGAssessment = (projectId, request) => {
  return fetch(`${apiUrl}/projects/${projectId}/esg/`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((response) => response.ok);
};

export const putSROIFinancialAssessment = (projectId, request) => {
  return fetch(`${apiUrl}/projects/${projectId}/sroi/financials/`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((response) => response.ok);
};

export const putSROITechnicalAssessment = (projectId, request) => {
  return fetch(`${apiUrl}/projects/${projectId}/sroi/technicals/`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((response) => response.ok);
};

export const putSROIPeopleAssessment = (projectId, request) => {
  return fetch(`${apiUrl}/projects/${projectId}/sroi/people/`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((response) => response.ok);
};

export const putChangeStatusOnDraft = (projectId, timeout) => {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    fetch(`${apiUrl}/projects/${projectId}/review/`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      signal: controller.signal,
    })
      .then((response) => {
        clearTimeout(timeoutId);
        if (!response.ok) {
          reject(new Error(`${response.status} - ${response.statusText}`));
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          reject(
            new Error('Richiesta scaduta. Riprova più tardi o contatta il supporto per assistenza.')
          );
        } else {
          reject(error);
        }
      });
  });
};

// export const putChangeStatusOnDraft = (projectId) => {
//   return fetch(`${apiUrl}/projects/${projectId}/review/`, {
//     method: 'PUT',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//     //body: JSON.stringify({}),
//   }).then((response) => response.ok);
// };

export const editUserPassword = (data) => {
  return fetch(`${baseUrl}/users/`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
};

export const putChangeStatusOnSubmitted = (projectId, timeout) => {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    fetch(`${apiUrl}/projects/${projectId}/submit/`, {
      method: 'PUT',
      headers: {
        ...getDefaultHeaders(),
      },
      signal: controller.signal,
    })
      .then((response) => {
        clearTimeout(timeoutId);
        if (!response.ok) {
          reject(new Error(`${response.status} - ${response.statusText}`));
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          reject(
            new Error('Richiesta scaduta. Riprova più tardi o contatta il supporto per assistenza.')
          );
        } else {
          reject(error);
        }
      });
  });
};
// export const putChangeStatusOnSubmitted = (projectId) => {
//   return fetch(`${apiUrl}/projects/${projectId}/submit/`, {
//     method: 'PUT',
//     headers: {
//       ...getDefaultHeaders(),
//     },
//     //body: JSON.stringify({}),
//   }).then((response) => response.ok);
// };

export const exportExcel = () => {
  return fetch(`${apiUrl}/projects/export`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/vnd.ms-excel',
    },
  })
    .then((r) => r.blob())
    .then((blob) => {
      const file = new File([blob], 'prova');
      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = 'projects.xlsx';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
};

//AUDIOVISIVI
export const getAUDTypes = () => {
  return fetch(`${apiUrl}/audtypes`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getAUDGenres = () => {
  return fetch(`${apiUrl}/audgenres`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const getAUDProjectsByProject = (projectId) => {
  return fetch(`${apiUrl}/projects/${projectId}/audprojects`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
    //body: JSON.stringify({}),
  }).then((r) => r.json());
};

export const postAUDProject = (projectId, request) => {
  return fetch(`${apiUrl}/projects/${projectId}/audprojects`, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((r) => r.json());
};

export const putAUDProject = (projectId, request) => {
  return fetch(`${apiUrl}/audprojects/${projectId}`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((r) => r.json());
};

export const deleteAUDProject = (projectId) => {
  return fetch(`${apiUrl}/audprojects/${projectId}`, {
    method: 'DELETE',
    headers: {
      ...getDefaultHeaders(),
    },
    //body: JSON.stringify({}),
  }).then((response) => response.ok);
};

export const getSroiSimpleDataDefinition = () => {
  return fetch(`${apiUrl}/sroisimpledatadefinition`, {
    method: 'GET',
    headers: {
      ...getDefaultHeaders(),
    },
  }).then((r) => r.json());
};

export const putSroiSimpleData = (projectId, request) => {
  return fetch(`${apiUrl}/projects/${projectId}/sroi/sroisimpledata`, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
    },
    body: JSON.stringify(request),
  }).then((response) => response.ok);
};

export const getProjectBase64 = async (reportNumberBase64, projectIdBase64) => {
  try {
    const resp = await fetch(
      `${baseUrl}/valutazione/risultato/${reportNumberBase64}/${projectIdBase64}`,
      {
        method: 'GET',
        headers: {
          ...getDefaultHeaders(),
        },
      }
    ).then((r) => r.json());

    return resp;
  } catch (err) {
    return err;
  }
};

const api = {
  getapiUrl,
  getScenari,
  getScenarioMeta,
  getScenario,
  getCountryfm,
  getAdminOrganization,
  getAdminRegion,
  getAdminSam,
  getAdminClasses,
  getScenarioSelect,
  getDefaultHeaders,
  getUser,

  getCountries,
  getDataset,
  getDatasets,
  postDataset,
  putDataset,
  deleteDataset,

  getUsers,
  getTranslations,
  getProjects,
  getProject,
  deleteProject,
  deleteUser,
  getQuestions,
  getRequestIdCheck,
  getLocations,
  getRegions,
  getProvinceByRegion,
  getTownByProvince,
  getProponents,
  getFinancings,
  getEsgCategories,
  getSectors,
  getSizes,
  getStructureClusters,
  getFinDataTaxonomies,
  getSroiTechnicalParams,
  getSroiPeopleParams,
  getInterventions,
  getInterventionTypes,
  getRatings,
  getTypologicalAverage,
  getPercentageESG,
  getPercentageSROI,
  getSurveyQuestions,
  getSroiRatingsParams,
  getProjectStatusByUUID,
  getStats,
  putESGAssessment,
  putSROIFinancialAssessment,
  putSROITechnicalAssessment,
  putSROIPeopleAssessment,
  putChangeStatusOnDraft,
  editUserPassword,
  createProject,
  createSurvey,
  createUser,
  exportExcel,
  getAUDTypes,
  getAUDGenres,
  getSroiSimpleDataDefinition,
  putSroiSimpleData,
};

export default api;
